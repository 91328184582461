import ChargingStation from '../../../model/ChargingStation';
import CsNumber from './dashboardItemValues/CsNumber';
import Initials from './dashboardItemValues/Initials';
import StationNotifications from './dashboardItemValues/StationNotifications';
import Status from './dashboardItemValues/Status';
import CommonDashboardProps from '../../../props/CommonDashboardProps';
import TransactionButton from './dashboardItemValues/TransactionButton';
import CommonDashboardTransactionProps from '../../../props/CommonDashboardTransactionProps';
import Kwh from './dashboardItemValues/Kwh';
import ChargingTime from './dashboardItemValues/ChargingTime';
import OccupationTime from './dashboardItemValues/OccupationTime';
import getStatusColours from '../../../hooks/useGetStatusColour';
import { isStationDown } from '../../../service/ChargingStationFunctions';
import { useState } from 'react';
import DisplayLocation from '../../../model/DisplayLocation';

interface DashboarItemProps extends CommonDashboardProps, CommonDashboardTransactionProps {
    chargingStation: ChargingStation;
    isAdminOrManager: boolean;
    chargingStationLocation: DisplayLocation;
}

export default function DashboardItem(props: DashboarItemProps) {
    const [showAdminSettings, setShowAdminSettings] = useState<boolean>(false);

    return (
        <>
            <div
                className={`dashboard-grid-item-container ${props.isAdminOrManager ? 'admin' : 'user'}`}
                style={{
                    borderColor: getStatusColours(
                        props.chargingStation.comStatus === 'DOWN' && !props.isAdminOrManager
                            ? 'DOWN'
                            : props.chargingStation.isBlocked
                              ? 'BLOCKED'
                              : props.chargingStation.ocppStatus
                    ).border
                }}
            >
                <div className="userContainer">
                    <CsNumber {...props} />
                    <Initials {...props} />
                    <Status {...props} />
                    <Kwh kWh={props.chargingStation.kWh} slowCharging={props.chargingStation.slowCharging} />
                    <ChargingTime chargingTime={props.chargingStation.chargingTime} />
                    <OccupationTime
                        {...props}
                        occupationTime={props.chargingStation.occupationTime}
                        chargingTime={props.chargingStation.chargingTime}
                        status={props.chargingStation.ocppStatus}
                    />
                </div>
                {props.isAdminOrManager ? (
                    isStationDown(props.chargingStation) ? (
                        <div className="adminContainerDown">
                            <StationNotifications {...props} />
                        </div>
                    ) : (
                        <div style={{ gridArea: 'AdminContainer' }}>
                            <div className="adminContainerUp">
                                <TransactionButton
                                    {...props}
                                    chargingStation={props.chargingStation}
                                    showAdminSettings={showAdminSettings}
                                    setShowAdminSettings={setShowAdminSettings}
                                />
                            </div>
                            <div className="adminContainerDown" hidden={!showAdminSettings}>
                                <StationNotifications {...props} />
                            </div>
                        </div>
                    )
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
