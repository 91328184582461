import UserStats from '../../model/stats/UserStats';
import InformationPopover from '../shared/InformationPopover';
import moment from 'moment-timezone';

interface UserStatsDetailProps {
    stat: UserStats;
    unmovedDuration: moment.Duration;
    badUnmovedDurationMinutes: number;
    eventKey: string;
    isSmallScreen: boolean;
}

function UserStatsDetail(props: UserStatsDetailProps) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: props.isSmallScreen ? 'column' : 'row', justifyContent: 'space-between' }}>
                {/* Left Column */}
                <div style={{ flex: '0 0 48%' }}>
                    <p>
                        <strong>
                            Location
                            <InformationPopover infoHeader="Location">
                                <p>The office where this session occurred. We only have information about sessions that occur at our offices.</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {props.stat.displayLocation.name}
                    </p>
                    <p>
                        <strong>
                            Start Time
                            <InformationPopover infoHeader="Start time">
                                <p>The date and time we detected the session started. This can be off by up to 10 minutes.</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {moment(props.stat.startDate).format('HH:mm DD/MM/YYYY')}
                    </p>
                    <p>
                        <strong>
                            End Time
                            <InformationPopover infoHeader="End time">
                                <p>The date and time we detected the session ended. This can be off by up to 10 minutes.</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {moment(props.stat.endDate).format('HH:mm DD/MM/YYYY')}
                    </p>
                    <p>
                        <strong>
                            Charging Time
                            <InformationPopover infoHeader="Charging time">
                                <p>The amount of time your vehicle was charging. This can be off by up to 10 minutes.</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {Math.floor(moment.duration(props.stat.chargingTime).asHours())} hour(s){' '}
                        {Math.floor(moment.duration(props.stat.chargingTime).minutes() % 60)} minute(s)
                    </p>
                    <p>
                        <strong>
                            Occupation Time
                            <InformationPopover infoHeader="Occupation time">
                                <p>The amount of time your vehicle was occupying the charging station. This can be off by up to 10 minutes.</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {Math.floor(moment.duration(props.stat.occupationTime).asHours())} hour(s){' '}
                        {Math.floor(moment.duration(props.stat.occupationTime).minutes() % 60)} minute(s)
                    </p>
                    {props.unmovedDuration.asMinutes() > 0 && (
                        <p>
                            <strong>
                                Unmoved Duration
                                <InformationPopover infoHeader="Unmoved duration">
                                    <p>The amount of time between your car being charged and it being moved.</p>
                                </InformationPopover>
                                : &nbsp;
                            </strong>
                            <span
                                style={
                                    props.unmovedDuration.asMinutes() > props.badUnmovedDurationMinutes ? { color: 'var(--bs-xploregroup-red)' } : {}
                                }
                            >
                                {props.unmovedDuration.hours()} hour(s) {props.unmovedDuration.minutes()} minute(s)
                            </span>
                        </p>
                    )}
                </div>
                <div style={{ flex: '0 0 48%' }}>
                    <p>
                        <strong>
                            Amount Charged
                            <InformationPopover infoHeader="Amount charged">
                                <p>The amount of electricity you charged during this session.</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {props.stat.kWh} kWh
                    </p>
                    <p>
                        <strong>
                            Was Load Balanced
                            <InformationPopover infoHeader="Was load balanced">
                                <p>Whether load balancing occurred during this charging session.</p>
                                <p>
                                    Load balancing occurs when there isn't sufficient electrical capacity to keep all the charging stations active all
                                    all the charging stations active simultaneously, causing some stations to pause partway through the charging
                                    partway through the charging session.
                                </p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {props.stat.wasLoadBalanced ? 'Yes' : 'No'}
                    </p>
                    <p>
                        <strong>
                            Occupation when finished charging
                            <InformationPopover infoHeader="Occupation when finished charging">
                                <p>The number of stations that were occupied out of the total stations when your car finished charging</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {props.stat.occupiedStationsWhenFinishedCharging}/{props.stat.totalChargingStations}
                    </p>
                    <p>
                        <strong>
                            Occupation when moved
                            <InformationPopover infoHeader="Occupation when moved">
                                <p>The number of stations that were occupied out of the total stations when you moved your car</p>
                            </InformationPopover>
                            : &nbsp;
                        </strong>
                        {props.stat.occupiedStationsWhenMoved}/{props.stat.totalChargingStations}
                    </p>
                    {props.stat.maxOccupiedStationsWhileUnmoved != null && (
                        <p>
                            <strong>
                                Max Occupation while unmoved
                                <InformationPopover infoHeader="Max occupation while unmoved">
                                    <p>
                                        The maximum number of stations that were occupied out of the total stations between when your car finished
                                        between when your car finished charging and when you moved your car
                                    </p>
                                </InformationPopover>
                                : &nbsp;
                            </strong>
                            {props.stat.maxOccupiedStationsWhileUnmoved}/{props.stat.totalChargingStations}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserStatsDetail;
