import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
    getAllChargingStationLocations,
    getAnnouncementOfLocation,
    getChargingStationsOfLocation,
    getFuelCardsOfUser,
    getNotificationToggle,
    getNotificationToggleMgr
} from '../../service/Api';
import '../../styling/dashboard.css';
import ChargingStation from '../../model/ChargingStation';
import StartTransactionModal from './transaction/StartTransactionModal';
import MessageModal from './MessageModal';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import NotificationTogglesLocation from './notificationToggle/NotificationTogglesLocation';
import LoginRedirect from '../login/LoginRedirect';
import NavigationBar from '../shared/NavigationBar';
import useCheckIfAdminOrManager from '../../hooks/useCheckIfAdminOrManager';
import DashboardContainer from './dashboardIslands/DashboardContainer';
import ToastMessage from '../shared/ToastMessage';
import LoadingSpinnerPage from '../shared/LoadingSpinnerPage';
import Footer from '../shared/Footer';
import SwitchButtonsContainer from './switchButtons/SwitchButtonsContainer';
import StopTransactionVerificationModal from './transaction/StopTransactionVerificationModal';
import LocationTitleContainer from './locationTitle/LocationTitleContainer';
import AnnouncementBanner from '../shared/AnnouncementBanner';
import { isAxiosError } from 'axios';
import NewEulaModal from './NewEulaModal';
import { useLocation } from 'react-router-dom';
import ApiError from '../shared/ApiError';
import UserFuelcard from '../../model/fuelcard/UserFuelcard';
import { getPreferredCsl } from '../../service/ChargingStationLocationFunctions';
import DisplayLocation from '../../model/DisplayLocation';

export default function Dashboard() {
    const preferredDashboardKey = 'preferredDashboard';
    const dashboardFilterKey = 'dashboardFilter';

    const browserLocation = useLocation();
    const userInfo = useGetUserInfo();
    const isAdminOrManager = useCheckIfAdminOrManager();

    const [allLocations, setAllLocations] = useState<DisplayLocation[]>([]);
    const [currentLocation, setCurrentLocation] = useState<DisplayLocation | null>();
    const [stationsOfCurrentLocation, setStationsOfCurrentLocation] = useState<ChargingStation[]>([]);
    const [stationsLastFetched, setStationsLastFetched] = useState<Date>(new Date());
    const [selectedChargingStation, setSelectedChargingStation] = useState<ChargingStation>({
        chargingTime: '',
        comStatus: '',
        fuelCardId: '',
        initials: '',
        kWh: '',
        key: '',
        lastUpdateTime: '',
        nr: 0,
        occupationTime: '',
        ocppStatus: '',
        serviceStatus: '',
        status: ''
    });

    const [isUserCharging, setIsUserCharging] = useState<boolean>(false);

    const [showStartTransactionModal, setShowStartTransactionModal] = useState(false);
    const [showStopTransactionVerification, setShowStopTransactionVerification] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [transactionMessage, setTransactionMessage] = useState('');

    const [showToastMessage, setShowToastMessage] = useState(false);
    const [showNotificationSettings, setShowNotificationSettings] = useState<boolean | null>(null);
    const [showNewEulaModal, setShowNewEulaModal] = useState(userInfo.newEula !== undefined);
    const [filter, setFilter] = useState<string | null>(localStorage.getItem(dashboardFilterKey) ?? '');

    const {
        data: allCslsData,
        isLoading: isAllCslsLoading,
        error: allCslsError
    } = useQuery('allCsls', () => getAllChargingStationLocations(), {
        refetchOnWindowFocus: false
    });

    const {
        data: currentCslStationsData,
        isLoading: isCurrentCslStationsDataLoading,
        isFetching: isCurrentCslStationsFetching,
        error: currentCslStationsError,
        refetch: currentCslStationsRefetch
    } = useQuery(
        ['currentCslStationsData', currentLocation?.name],
        () => getChargingStationsOfLocation(currentLocation?.name ? currentLocation?.name : ''),
        {
            enabled: currentLocation?.name !== undefined,
            refetchInterval: 300000,
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false
        }
    );

    const { data: fuelCardsOfUser, isLoading: isFuelcardsOfUserLoading } = useQuery<UserFuelcard[]>('fuelCardsOfUser', getFuelCardsOfUser, {
        refetchOnWindowFocus: false
    });

    const {
        data: notificationToggle,
        isLoading: notificationLoading,
        error: notificationError,
        refetch: refetchNotifications
    } = useQuery('notificationToggle', () => getNotificationToggle());
    const {
        data: notificationToggleMgr,
        isLoading: notificationLoadingMgr,
        error: notificationErrorMgr,
        refetch: refetchNotificationsMgr
    } = useQuery('notificationToggleMgr', () => getNotificationToggleMgr(), { enabled: isAdminOrManager });

    const {
        data: announcement,
        isLoading: announcementLoading,
        error: announcementError
    } = useQuery(['announcementQuery', currentLocation?.name], () => getAnnouncementOfLocation(currentLocation?.name ?? ''), {
        enabled: currentLocation != null && currentLocation.name != null,
        refetchInterval: 300000
    });

    /**
     *  Once all the charging station locations are loaded:
     *  Either select the last used CSL (based on localstorage)
     *  Or fall back to the first CSL
     */
    useEffect(() => {
        if (allCslsData != null && allCslsData.data != null) {
            setAllLocations(allCslsData.data);
            setCurrentLocation(getPreferredCsl(allCslsData.data));
        }
    }, [allCslsData]);

    /**
     * Once the charging stations of the currently selected charging station location have been loaded
     * Put these stations into state after doing null checks
     */
    useEffect(() => {
        if (currentCslStationsData != null && currentCslStationsData.data != null) {
            setStationsOfCurrentLocation(currentCslStationsData.data);
        }
        setStationsLastFetched(new Date());
    }, [currentCslStationsData]);

    /**
     * Once the fuelcards have been loaded and the charging stations of the current location have been loaded
     * Check if the user is charging at any of the stations with any of their cards
     */
    useEffect(() => {
        if (fuelCardsOfUser === undefined || isFuelcardsOfUserLoading) {
            setIsUserCharging(false);
            return;
        }

        const userFuelcardIds = fuelCardsOfUser.map((fcId) => (fcId === undefined ? '' : fcId.fuelcardId)).filter((fcId) => fcId !== '');

        const csFcIds = stationsOfCurrentLocation.map((cs) => cs.fuelCardId);

        for (const csFcId of csFcIds) {
            if (userFuelcardIds.includes(csFcId)) {
                setIsUserCharging(true);
                return;
            }
        }

        setIsUserCharging(false);
    }, [fuelCardsOfUser, isFuelcardsOfUserLoading, stationsOfCurrentLocation]);

    /**
     * Decide if we need to open the settings based on URL params
     */
    useEffect(() => {
        const loginRedirect = localStorage.getItem('redirectedFromLoginWithSearchParams');
        const searchParams = new URLSearchParams(browserLocation.search);

        if (searchParams.get('manageSettings') === 'true') {
            setShowNotificationSettings(true);

            if (loginRedirect === null) {
                localStorage.setItem('manageSettings', 'true');
            }

            const locationSearchParam = searchParams.get('locationName');
            if (locationSearchParam !== null && locationSearchParam.length > 0) {
                if (loginRedirect === null) {
                    localStorage.setItem('manageSettingsOfLocation', locationSearchParam);
                }
                localStorage.setItem(preferredDashboardKey, locationSearchParam);
            }

            localStorage.removeItem('redirectedFromLoginWithSearchParams');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const savedFilter = localStorage.getItem(dashboardFilterKey);
        if (savedFilter) {
            setFilter(savedFilter);
        } else if (isUserCharging) {
            setFilter('session');
            localStorage.setItem(dashboardFilterKey, 'session');
        } else {
            setFilter('');
            localStorage.setItem(dashboardFilterKey, '');
        }
    }, [isUserCharging, setIsUserCharging]);

    useEffect(() => {
        if (filter) {
            localStorage.setItem(dashboardFilterKey, filter);
        }
    }, [filter, setFilter]);

    const changeSelectedLocation = (csl: DisplayLocation) => {
        localStorage.setItem(preferredDashboardKey, csl.name);
        setCurrentLocation(csl);
    };

    const getAvailableChargingStationNumbers = (): number[] => {
        return stationsOfCurrentLocation
            .filter((station) => station.comStatus === 'UP')
            .filter((station) => station.ocppStatus === 'AVAILABLE')
            .filter((station) => station.serviceStatus === 'IN_SERVICE')
            .map((station) => station.nr);
    };

    if (allCslsError && isAxiosError(allCslsError) && (allCslsError.response?.status === 403 || allCslsError.response?.status === 401)) {
        return (
            <>
                <NavigationBar />
                {currentLocation != null ? (
                    <AnnouncementBanner show={!(announcementLoading || announcementError)} announcement={announcement ?? null} />
                ) : (
                    <></>
                )}
                <p style={{ margin: '1%', textAlign: 'center' }}>
                    Dashboard data couldn't be loaded, possibly due to an expired or invalid session. You can attempt to resolve this by logging in
                    again. <a href="/login">Login</a>
                </p>
            </>
        );
    } else if (allCslsError) {
        return (
            <>
                <NavigationBar />
                {currentLocation != null ? (
                    <AnnouncementBanner show={!(announcementLoading || announcementError)} announcement={announcement ?? null} />
                ) : (
                    <></>
                )}

                <div style={{ width: '80%', margin: '1em auto' }}>
                    <ApiError error={allCslsError} />
                </div>
            </>
        );
    } else if (isAllCslsLoading || currentLocation == null || isFuelcardsOfUserLoading) {
        return <LoadingSpinnerPage />;
    } else if (!(announcementLoading || announcementError) && announcement?.outOfService === true && userInfo.role != 'ADMIN') {
        return (
            <>
                <NavigationBar />
                <SwitchButtonsContainer
                    userFuelcards={fuelCardsOfUser ? fuelCardsOfUser : []}
                    selectedLocation={currentLocation}
                    setSelectedLocation={changeSelectedLocation}
                    locationOptions={allLocations}
                    chargingStations={stationsOfCurrentLocation}
                    showFilter={false}
                    filter={filter}
                    setFilter={setFilter}
                    isUserCharging={isUserCharging}
                />
                <AnnouncementBanner show={true} announcement={announcement} />
            </>
        );
    }

    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <NavigationBar />
            <ToastMessage showToastMessage={showToastMessage} setShowToastMessage={setShowToastMessage} message="Copied fuel card to clipboard." />
            <AuthenticatedTemplate>
                <StartTransactionModal
                    show={showStartTransactionModal}
                    onHide={() => setShowStartTransactionModal(false)}
                    location={currentLocation.name}
                    chargingStation={selectedChargingStation}
                    setTransactionMessage={setTransactionMessage}
                    setShowMessage={setShowMessageModal}
                    setShowStartTransactionModal={setShowStartTransactionModal}
                    displayName={currentLocation.name}
                />
                <MessageModal show={showMessageModal} onHide={() => setShowMessageModal(false)} message={transactionMessage} staticBackdrop={true} />
                {userInfo.newEula !== undefined && (
                    <NewEulaModal show={showNewEulaModal} onHide={() => setShowNewEulaModal(false)} eula={userInfo.newEula} userInfo={userInfo} />
                )}
                <StopTransactionVerificationModal
                    show={showStopTransactionVerification}
                    setShow={setShowStopTransactionVerification}
                    setShowMessageModal={setShowMessageModal}
                    setTransactionMessage={setTransactionMessage}
                    refetchChargingStations={currentCslStationsRefetch}
                    location={currentLocation?.name ? currentLocation.name : ''}
                    nr={selectedChargingStation.nr}
                />
                <SwitchButtonsContainer
                    userFuelcards={fuelCardsOfUser ? fuelCardsOfUser : []}
                    selectedLocation={currentLocation}
                    setSelectedLocation={changeSelectedLocation}
                    locationOptions={allLocations}
                    chargingStations={stationsOfCurrentLocation}
                    showFilter
                    filter={filter}
                    setFilter={setFilter}
                    isUserCharging={isUserCharging}
                />
                <AnnouncementBanner show={!(announcementLoading || announcementError)} announcement={announcement ?? null} />

                <LocationTitleContainer
                    locationName={currentLocation.name}
                    setShowNotificationSettings={setShowNotificationSettings}
                    showNotificationSettings={showNotificationSettings}
                    filter={filter}
                    refetchChargingStations={currentCslStationsRefetch}
                    isFetchingChargingStations={isCurrentCslStationsFetching}
                    stationsLastFetched={stationsLastFetched}
                    currentLocation={currentLocation}
                    chargingStations={stationsOfCurrentLocation}
                />

                <NotificationTogglesLocation
                    role={userInfo.role}
                    location={currentLocation}
                    stations={stationsOfCurrentLocation}
                    show={showNotificationSettings ? showNotificationSettings : false}
                    notificationToggle={notificationToggle}
                    notificationToggleMgr={notificationToggleMgr}
                    togglesLoading={notificationLoading || notificationLoadingMgr}
                    notificationToggleError={notificationError}
                    notificationToggleMgrError={notificationErrorMgr}
                    setShowMessageModal={setShowMessageModal}
                    setTransactionMessage={setTransactionMessage}
                    refetchNotifications={refetchNotifications}
                    refetchNotificationsMgr={refetchNotificationsMgr}
                    availableCharingStations={getAvailableChargingStationNumbers()}
                    isUserCharging={isUserCharging}
                />
                <DashboardContainer
                    setShowToastMessage={setShowToastMessage}
                    chargingStations={stationsOfCurrentLocation}
                    chargingStationsLoading={isCurrentCslStationsDataLoading}
                    currentCslStationsError={currentCslStationsError}
                    notificationToggleMgr={notificationToggleMgr}
                    setShowMessageModal={setShowMessageModal}
                    setTransactionMessage={setTransactionMessage}
                    refetchNotificationsMgr={refetchNotificationsMgr}
                    locationName={currentLocation.name}
                    setSelectedChargingStation={setSelectedChargingStation}
                    setShowStartTransactionModal={setShowStartTransactionModal}
                    refetchChargingStations={currentCslStationsRefetch}
                    setShowStopTransactionVerification={setShowStopTransactionVerification}
                    filter={filter}
                    chargingStationLocation={currentLocation}
                    userFuelcards={fuelCardsOfUser ? fuelCardsOfUser : []}
                />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginRedirect />
            </UnauthenticatedTemplate>
            <div style={{ marginTop: 'auto' }}>
                <Footer />
            </div>
        </div>
    );
}
