import { Button, Form, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import SearchFuelcard from '../../model/fuelcard/SearchFuelcard';
import { getAllChargingStationLocations, getChargingStationsOfLocation, startTransaction } from '../../service/Api';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { isAxiosError } from 'axios';
import CslShort from '../../model/CslShort';
import moment from 'moment-timezone';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import { checkIfAdminOrManagerForLocation } from '../../service/AuthorizationFunctions';
import { TIMEZONE } from '../shared/Timezone';
import DisplayLocation from '../../model/DisplayLocation';
import ChargingStation from '../../model/ChargingStation';

interface StartTransactionSearchPageModalProps {
    show: boolean;
    onHide: () => void;
    location: CslShort;
    chargingStationNr: number;
    setShowStartTransactionModal: React.Dispatch<React.SetStateAction<boolean>>;
    fuelCard: SearchFuelcard;
    displayLocations: DisplayLocation[];
    setDisplayLocations: React.Dispatch<React.SetStateAction<DisplayLocation[]>>;
    setTransactionMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SelectOption {
    value: string;
    label: string;
    size: number;
}

type Inputs = {
    location: string;
    chargingStation: number;
};

export default function StartTransactionSearchPageModal(props: StartTransactionSearchPageModalProps) {
    const userInfo = useGetUserInfo();
    const { handleSubmit, setValue } = useForm<Inputs>();
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

    const { data: displayLocationsFromQuery } = useQuery('displayLocationsSearchPage', () => getAllChargingStationLocations(), {
        enabled: props.displayLocations.length === 0 && props.show,
        refetchOnWindowFocus: false
    });

    const { data: chargingStationsData, isLoading: isChargingStationsLoading } = useQuery(
        ['chargingStations', selectedLocation],
        () => getChargingStationsOfLocation(selectedLocation ? selectedLocation : ''),
        {
            enabled: !!selectedLocation // Only fetch when a location is selected
        }
    );
    const [selectedLocationMaxSize, setSelectedLocationMaxSize] = useState(0);
    const options: SelectOption[] = (displayLocationsFromQuery?.data ?? [])
        .filter((dl: DisplayLocation) => checkIfAdminOrManagerForLocation(dl.name, userInfo))
        .map((dl: DisplayLocation) => {
            return {
                value: dl.name,
                label: dl.name
            };
        });

    useEffect(() => {
        // Fetch chargingStationLocations only if it's not set yet
        if (displayLocationsFromQuery && displayLocationsFromQuery.data.length > 0 && props.displayLocations.length === 0) {
            props.setDisplayLocations(displayLocationsFromQuery.data);
        }
        // eslint-disable-next-line
    }, [props.show]);

    useEffect(() => {
        if (options.length > 0 && selectedLocationMaxSize === 0) {
            const defaultOption = getDefaultOption(options, props.location.name);
            if (defaultOption) {
                handleLocationChange(defaultOption);
                setSelectedLocationMaxSize(defaultOption.size);
            }
        }
        // eslint-disable-next-line
    }, [options]);

    const handleStartTransaction: SubmitHandler<Inputs> = async (data) => {
        if (props.fuelCard.id === undefined) {
            props.setTransactionMessage('Could not start transaction, something went wrong.  Please try again later');
            return;
        }

        try {
            props.onHide();
            await startTransaction(data.location, data.chargingStation, props.fuelCard.id);
        } catch (error) {
            if (isAxiosError(error) && error.response?.data?.detail) {
                props.setTransactionMessage(error.response?.data?.detail);
            } else {
                props.setTransactionMessage('Could not start transaction, something went wrong.  Please try again later');
            }
            props.onHide();
            props.setShowMessage(true);
        }
    };

    function handleClose(): void {
        props.setShowStartTransactionModal(false);
    }

    function getDefaultOption(options: SelectOption[], location: string) {
        const defaultOption = options.find((option) => option.value === location);
        if (defaultOption) {
            return defaultOption;
        } else {
            return options[0];
        }
    }

    const handleLocationChange = (option: { value: string; label: string }) => {
        setSelectedLocation(option.value);
        setValue('location', option.value);
    };

    const handleChargingStationChange = (option: { value: number; label: string }) => {
        setValue('chargingStation', option.value);
    };
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.location.name.length > 0 && (
                        <p style={{ fontWeight: 'normal', margin: '0', fontSize: '1rem' }}>
                            (Redirected from dashboard ({props.location.displayName} #{props.chargingStationNr}))
                        </p>
                    )}
                    Start a charging session for {props.fuelCard.name}
                    <p style={{ fontWeight: 'normal', margin: '0', fontSize: '1rem' }}>
                        Card last used: {moment(props.fuelCard.lastUsed).tz(TIMEZONE).format('DD/MM/YYYY HH:mm')}
                    </p>
                    <p style={{ fontWeight: 'normal', margin: '0', fontSize: '1rem' }}>
                        Card last updated on: {moment(props.fuelCard.lastModificationDate).tz(TIMEZONE).format('DD/MM/YYYY HH:mm')}
                    </p>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(handleStartTransaction)}>
                <Modal.Body>
                    <div className="startTransactionModalBody">
                        <Form.Group style={{ textAlign: 'left' }} className="mb-3">
                            <Form.Label>Location:</Form.Label>
                            {options.length > 0 ? (
                                <Select
                                    classNamePrefix="react-select"
                                    className="selectDropdown"
                                    onChange={(selection) => {
                                        if (selection) {
                                            handleLocationChange(selection);
                                        }
                                    }}
                                    options={options}
                                    defaultValue={getDefaultOption(options, props.location.name)}
                                />
                            ) : (
                                <p>Loading...</p>
                            )}
                        </Form.Group>
                        <Form.Group style={{ textAlign: 'left' }} className="mb-3">
                            <Form.Label>Charging Station:</Form.Label>
                            {isChargingStationsLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <Select
                                    classNamePrefix="react-select"
                                    className="selectDropdown"
                                    options={(chargingStationsData?.data ?? [])
                                        .filter((station: ChargingStation) => station.ocppStatus !== 'CHARGING')
                                        .map((station: ChargingStation) => ({
                                            value: station.nr,
                                            label: `${station.nr} - ${station.ocppStatus}`
                                        }))}
                                    onChange={(option) => handleChargingStationChange(option as any)}
                                    isDisabled={!selectedLocation}
                                />
                            )}
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button disabled={options.length === 0} className="startTransactionButton" type="submit">
                        Start Transaction
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
