import SearchFuelcard from '../../model/fuelcard/SearchFuelcard';
import moment from 'moment-timezone';
import { useMediaQuery } from 'react-responsive';
import { Badge, Button, DropdownButton, DropdownDivider, DropdownItem, Modal } from 'react-bootstrap';
import { blockFuelcard, deleteUserFuelcard, getFuelCardSerial, searchFuelcards, sortSearchFuelcards, unblockFuelcard } from '../../service/Api';
import useCheckIfAdmin from '../../hooks/useCheckIfAdmin';
import TableHeader from './TableHeader';
import Page from '../../model/Page';
import SortingOption from './SortingOption';
import { useState } from 'react';
import { SortType } from '../../model/enum/SortType';
import { TIMEZONE } from '../shared/Timezone';
import SendMailFromTemplate from '../management/mail/SendMailFromTemplate';
import { Link } from 'react-router-dom';
import Cross from '../shared/icon/Cross';
import ThreeDots from '../shared/icon/ThreeDots';
import Copy from '../shared/icon/Copy';
import Ban from '../shared/icon/Ban';
import Check from '../shared/icon/Check';
import Car from '../shared/icon/Car';
import MailPlus from '../shared/icon/MailPlus';
import Play from '../shared/icon/Play';
import Delete from '../shared/icon/Delete';

interface SearchFuelcardResultProps {
    fuelcards: SearchFuelcard[];
    setShowToastMessage: React.Dispatch<React.SetStateAction<boolean>>;
    setShowStartTransactionModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedFuelCard: React.Dispatch<React.SetStateAction<SearchFuelcard>>;
    setSearchResult: React.Dispatch<React.SetStateAction<SearchFuelcard[]>>;
    searchTerm: string;
    page: Page;
    setPage: React.Dispatch<React.SetStateAction<Page>>;
    setSortingOption: React.Dispatch<React.SetStateAction<SortingOption>>;
}

export default function SearchFuelcardResult(props: SearchFuelcardResultProps) {
    const isAdmin = useCheckIfAdmin();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });
    const [sortingField, setSortingField] = useState<string>('name');
    const [sortingType, setSortingType] = useState<SortType>(SortType.ASC);
    const [mailOverlayFuelcard, setMailOverlayFuelcard] = useState<SearchFuelcard | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [toDeleteFuelcard, setToDeleteFuelcard] = useState<SearchFuelcard | null>(null);

    const handleSortChange = (field: string) => {
        let newSortType: SortType;
        if (sortingField === field) {
            switch (sortingType) {
                case SortType.NO_SORT:
                    newSortType = SortType.ASC;
                    break;
                case SortType.ASC:
                    newSortType = SortType.DESC;
                    break;
                case SortType.DESC:
                    newSortType = SortType.NO_SORT;
                    break;
                default:
                    newSortType = SortType.NO_SORT;
            }
        } else {
            newSortType = SortType.ASC;
        }

        setSortingField(field);
        setSortingType(newSortType);

        if (newSortType === SortType.NO_SORT) {
            searchFuelcards(props.searchTerm, 0).then((resp) => {
                props.setSearchResult(resp.data.content);
                // Reset to page 0 if sorting is reset
                props.setSortingOption({ field: 'name', type: 'ASC' });
                props.setPage({
                    currentPage: resp.data.number,
                    totalPages: resp.data.totalPages,
                    isEmpty: resp.data.totalElements === 0
                });
            });
        } else {
            sortSearchFuelcards(props.searchTerm, 0, field, newSortType === SortType.ASC ? 'ASC' : 'DESC').then((resp) => {
                props.setSearchResult(resp.data.content);
                props.setSortingOption({ field, type: newSortType === SortType.ASC ? 'ASC' : 'DESC' });
                props.setPage({
                    currentPage: resp.data.number,
                    totalPages: resp.data.totalPages,
                    isEmpty: resp.data.totalElements === 0
                });
            });
        }
    };

    const copyFuelcardId = (fuelcard: SearchFuelcard) => {
        if (fuelcard.id !== undefined) {
            navigator.clipboard.writeText(fuelcard.id).then(() => {
                props.setShowToastMessage(true);
            });
        }
    };

    const copyFuelSerialNumber = (fuelcard: SearchFuelcard) => {
        if (fuelcard.id === undefined) {
            return;
        }
        getFuelCardSerial(fuelcard.id)
            .then((result) => navigator.clipboard.writeText(result.data))
            .then(() => {
                props.setShowToastMessage(true);
            });
    };

    const changeBlockedStatus = async (isBlocked: boolean, id: string) => {
        isBlocked ? await unblockFuelcard(id) : await blockFuelcard(id);

        const updatedFuelcards = props.fuelcards.map((fuelcard) => {
            if (fuelcard.id === id) {
                return { ...fuelcard, isBlocked: !isBlocked };
            }
            return fuelcard;
        });

        props.setSearchResult(updatedFuelcards);
    };

    function reformatTime(time?: Date | null) {
        if (!time) {
            return '';
        }
        return moment(time).tz(TIMEZONE).format('DD/MM/YYYY HH:mm');
    }

    const deleteFuelcard = async (id: string) => {
        try {
            await deleteUserFuelcard(id);
            setShowDeleteModal(false);
            searchFuelcards(props.searchTerm, 0).then((resp) => {
                props.setSearchResult(resp.data.content);
                // Reset to page 0 if sorting is reset
                props.setSortingOption({ field: 'name', type: 'ASC' });
                props.setPage({
                    currentPage: resp.data.number,
                    totalPages: resp.data.totalPages,
                    isEmpty: resp.data.totalElements === 0
                });
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {showDeleteModal ? (
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the fuelcard '{toDeleteFuelcard?.displayName}'?</Modal.Body>
                    <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => deleteFuelcard(toDeleteFuelcard?.id ?? '')}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <></>
            )}
            {mailOverlayFuelcard != null ? (
                <SendMailFromTemplate
                    show={true}
                    setShow={(show) => {
                        if (!show) setMailOverlayFuelcard(null);
                    }}
                    contactEmail={mailOverlayFuelcard.contactEmail ?? ''}
                    name={mailOverlayFuelcard.name ?? ''}
                />
            ) : (
                <></>
            )}
            <table className="fuelcard-search-table">
                <thead>
                    <tr>
                        <TableHeader
                            title="Display name"
                            fieldName="displayName"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Name"
                            fieldName="name"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Email"
                            fieldName="contactEmail"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Mobile"
                            fieldName="mobile"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="License Plate"
                            fieldName="licensePlate"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Car"
                            fieldName="modelAndBrand"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Last Used"
                            fieldName="lastUsed"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Last Updated"
                            fieldName="lastModificationDate"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <TableHeader
                            title="Status"
                            fieldName="isBlocked"
                            currentSortField={sortingField}
                            currentSortType={sortingType}
                            onSortChange={handleSortChange}
                        />
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.fuelcards?.map((fuelcard: SearchFuelcard) => (
                        <tr key={fuelcard.id}>
                            <td>{fuelcard.displayName}</td>
                            <td>{fuelcard.name}</td>
                            <td>{fuelcard.contactEmail}</td>
                            <td>{fuelcard.mobile}</td>
                            <td>{fuelcard.licensePlate}</td>
                            <td>{fuelcard.modelAndBrand}</td>
                            <td>{reformatTime(fuelcard.lastUsed)}</td>
                            <td>{reformatTime(fuelcard.lastModificationDate)}</td>
                            <td style={{ textAlign: 'center' }}>
                                <Badge pill bg={fuelcard.isBlocked ? 'danger' : 'secondary'}>
                                    {fuelcard.isBlocked ? (
                                        <>
                                            <Cross fill="var(--bs-xploregroup-yellow)" alt="Blocked icon" />
                                            Blocked
                                        </>
                                    ) : (
                                        <>Not Blocked</>
                                    )}
                                </Badge>
                            </td>
                            <td style={{ textAlign: isDesktopOrLaptop ? 'center' : 'left' }}>
                                <DropdownButton title={<ThreeDots />} variant="outline-secondary" dir="up" className={'no-dropdown-arrow'}>
                                    <DropdownItem onClick={() => copyFuelcardId(fuelcard)}>
                                        Copy ID <Copy />
                                    </DropdownItem>
                                    {isAdmin ? (
                                        <>
                                            <DropdownItem onClick={() => copyFuelSerialNumber(fuelcard)}>
                                                Copy serial number <Copy />
                                            </DropdownItem>
                                            <DropdownDivider />
                                            {fuelcard.id && (
                                                <DropdownItem
                                                    //@ts-ignore
                                                    onClick={() => changeBlockedStatus(fuelcard.isBlocked, fuelcard.id)}
                                                >
                                                    {fuelcard.isBlocked ? (
                                                        <>
                                                            <Check style={{ color: 'var(--bs-xploregroup-green)' }} /> Unblock fuelcard
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Ban style={{ color: 'var(--bs-xploregroup-red)' }} /> Block fuelcard
                                                        </>
                                                    )}
                                                </DropdownItem>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <DropdownDivider />
                                        </>
                                    )}

                                    <Link
                                        to={{ pathname: '/management/car/usercar' }}
                                        state={{
                                            originalSearchText: props.searchTerm,
                                            username: fuelcard.name,
                                            userId: fuelcard.userId,
                                            userEmail: fuelcard.contactEmail
                                        }}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <DropdownItem as={'div'}>
                                            <Car /> Manage car
                                        </DropdownItem>
                                    </Link>
                                    <DropdownItem
                                        onClick={() => {
                                            props.setShowStartTransactionModal(true);
                                            props.setSelectedFuelCard(fuelcard);
                                        }}
                                    >
                                        <Play style={{ color: 'var(--bs-xploregroup-green)' }} /> Start transaction
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            setToDeleteFuelcard(fuelcard);
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        <Delete /> Delete fuelcard
                                    </DropdownItem>
                                    <DropdownDivider />
                                    <DropdownItem onClick={() => setMailOverlayFuelcard(fuelcard)}>
                                        <MailPlus /> Send email from template
                                    </DropdownItem>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}
                    <tr className="no-results" hidden={props?.fuelcards?.length !== 0}>
                        <td colSpan={10}>
                            <h1>No results</h1>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
